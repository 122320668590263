@import url(https://unpkg.com/bootstrap@4.1.0/dist/css/bootstrap.min.css);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
    margin-top: 52px;
    position: absolute;
    width: 530px;
    
  }
  
  .alert-hidden {
    opacity: 0;
    transition: all 250ms linear;
    display: none;
  }
* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
}

/* App page */

.app-page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #ddd;
  min-height: 100vh;
}

/* Button */

.btn {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  background: #333;
  padding: 12px 55px;
  border: none;
  cursor: pointer;
}

.btn-login:hover,
.btn:hover {
  background: #007bff;
  color: #FFF !important;
}

.btn-center {
  display: border-box;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 5px;
}

.btn-login {
  display: inline-block;
  font-size: 1em;
  color: #fff;
  background: #333;
  padding: 12px 45px;
  border: none;
  cursor: pointer;
  border-radius: .25rem;
  width: 40%;
}

.btn-block {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  background: #333;
  padding: 10px 30px;
  border: none;
  cursor: pointer;
}

/* Contact */

.contact {
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  display: border-box;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.contact .errorSend {
  margin-left: 25px;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.contact h1 {
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.form-group,
.form p {
  margin-bottom: 20px;
  margin-left: 25px;
}

.contact label {
  display: block;
  margin-bottom: 5px;
}

.contact input,
.contact textarea {
  width: 90%;
  padding: 10px;
  border: 1px #ddd solid;
}

.contact .textarea {
  height: 200px;
}

.contact input:focus,
.contact textarea:focus {
  outline: none;
  border-color: blue;
}

/* Dashboard */

.dashboard {
  position: relative;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.dashboard p {
  padding: 15px;
}

.dashboard h1 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.dashboard h2 {
  margin-left: 5px;
  margin-bottom: 20px;
}

.dashboard .heatMap,
.home .heatMap {
  width: 100%;
  height: 90vh;
  position: relative;
}

.bar {
  margin-bottom: 30px;
  padding-left: 20px;
  padding: 0 auto;
}

.sBar:hover {
  fill: brown;
}

/* Error Component */

.ErrorComponent {
  -webkit-flex: 0.90 1;
          flex: 0.90 1;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  color: black;
  background: #f9f9f9;
  padding-bottom: 30%;
}

.NotFound {
  display: block;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 32.5vh;
}

.ErrorComponent h1 {
  font-size: 10vh;
  font-weight: bold;
}

.ErrorComponent h2 {
  font-size: 6.5vh;
}

.ErrorComponent h3 {
  font-size: 4vh;
  text-align: center;
}

/* Footer Component */

.footer {
  display: -webkit-flex;
  display: flex;
  background: #333;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px;
}

.footer p {
  font-size: 14px;
  color: white;
  text-align: center;
  margin: 0;
}

/* Header Component */

.nav-link {
  font-size: 1rem !important;
  font-weight: 600;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* aliniem icon-urile in centrul headerului */
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* adaugam spatii intre div-uri */
  padding: 5px 5px;
  /* header-ul va ramane, indiferent de cat scroll facem */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #eee;
}

.header-logo {
  height: 40px;
  /* Scalam logo-ul astfel incat sa pastram aspect ratio-ul */
  object-fit: contain;
  margin-left: 20px;
}

.header-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-icon {
  margin-right: 15px;
}


/* Utility */

.clr {
  clear: both;
}

.flex-break {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  height: 0;
}

/* Sidebar */

.sidebar {
  /* Acest container are width-ul de 20% */
  -webkit-flex: 0.10 1;
          flex: 0.10 1;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  height: 100%;
  background: #ddd;
}

/* Active sidebarrow */
.sidebar .active .sidebarRow {
  background: #eee;
}

/* Sidebar Row */

.sidebarRow {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  padding: 20px;
}

.sidebarRow-icon {
  color: #606060;
  font-size: large !important;
  margin-right: 20px;
  width: 18px;
}

.sidebarRow-title {
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 12px;
  font-weight: 650;
  height: 18px;
  margin: 0;
  text-align: start;
}

.sidebarRow:hover {
  background-color: #eee;
  cursor: pointer;
}

.sidebarRow:hover>.sidebarRow-icon {
  color: blue;
}

.sidebarRow:hover>.sidebarRow-title {
  font-weight: bold;
}


/* Schimbam culoarea iconului din containerul selectat */
.sidebarRow.selected>.sidebarRow-icon {
  color: blue;
}

.sidebarRow.selected>.sidebarRow-title {
  font-weight: bold;
}

.Toastify__toast-container {
  margin-top: 29px;
}

/* Mobile Style */

@media (max-width: 768px) {
  .sidebarRow {
    display: block;
    text-align: center;
    font-size: 10px;
    padding: 15px;
  }

  .sidebarRow-title {
    display: none;
  }

  .sidebarRow-icon {
    margin-right: 0px;
  }

  .header-logo {
    margin-left: 0;
  }

  .btn-login {
    padding-left: 5%;
    padding-right: 5%;
  }

  .form-group-settings {
    width: 75% !important;
  }
}

@media (max-width: 480px) {

  .btn-block {
    padding: 0px;
    padding: 10px;

  }

  .btn-login {
    width: 55%;
    padding: 15px 15px;
  }

  .Toastify__toast {
    width: 65%;
    margin-left: 31%;
    margin-top: 50px;
  }

}

/* Settings */
.settings {
  text-align: center;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  display: border-box;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.settings-content {
  display: inline-block;
  width: 90%;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  vertical-align: middle;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
}

.settings-title {
  color: black;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 30px;
}

.form-group-settings {
  width: 45%;
  margin-bottom: 25px;
  margin-left: 10%;
  padding: 0;
  text-align: start;
}

.settings input {
  width: 100%;
  height: 45px;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: .25rem;
}

.settings .textarea {
  height: 200px;
}

.settings input:focus {
  outline: none;
  border-color: blue;
}

.settings form {
  margin-top: 40px;
}

.settings h1 {
  margin-top: 15px;
}

.settings h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.settings label {
  font-weight: 600;
  font-size: 0.85rem;
}

.general-info .btn {
  font-weight: 600;
  font-size: 1.1rem;
  padding: 15px 32px !important;
  margin-top: 25px;
}

.change-password .btn {
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 25px;
  padding: 12px 16px !important;
}

.settings-header-changepassword {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: start;
  margin-left: 5%;
}

.dashboard .datepicker-withoutTime,
.dashboard .react-datepicker-wrapper {
  position: absolute;
  z-index: 1;
  top: 4.4%;
  padding: 0;
}

.react-datepicker {
  margin-top: 17px;
  margin-left: 1px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #2A87D0 !important;
  color: white;
}

.settings-header {
  text-align: start;
  margin-left: 5%;
  margin-top: 40px;

}

.settings .btn {
  padding: 12px 23px;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: white !important;
}

/* Home Chart */

.home {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.home .app {
  display: block;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  height: 300px;
  margin-bottom: 50px;
}

.home .charts-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: auto;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.home .chart {
  height: 100%;
  width: 90%;
}

.home h1 {
  font-size: 24px;
  width: 100%;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .home .chart {
    width: 95%;
  }
}

.pollutantSelector {
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
  color: #333;
  width: 30px !important;
  height: 30px !important;

}

.pollutantSelectorButton {
  margin-right: 5px;
  color: #fff;
  background: #333;
  border-radius: .25rem;
  padding: 5px;
  border: none;
}

.pollutantSelectorButton:hover {
  background: #007bff;
  color: #FFF !important;
}

.pollutantSelectorWindow {
  position: absolute;
  top: 80px;
  right: 5px;
  z-index: 99;
  background-color: #ddd;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  width: 250px !important;
  height: 400px !important;
  padding: 30px;
}

.pollutantSelectorWindowLocation {
  position: absolute;
  top: 80px;
  right: 5px;
  z-index: 99;
  background-color: #ddd;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  width: 250px !important;
  height: 325px !important;
  padding: 30px;
}


.pollutantSelectorWindow h1,
.pollutantSelectorWindowLocation h1 {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.pollutantSelectorWindow p,
.pollutantSelectorWindowLocation p {
  text-align: left;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.pollutantSelectorWindow .datepicker-homelocations,
.pollutantSelectorWindow .react-datepicker-wrapper,
.pollutantSelectorWindowLocation .datepicker-homelocations,
.pollutantSelectorWindowLocation .react-datepicker-wrapper {
  margin-bottom: 10px;
  right: 25px;
}

.pollutantSelectorWindow .react-datepicker,
.pollutantSelectorWindowLocation .react-datepicker {
  z-index: 15;
  right: 0px;
  top: 0px;
  width: 328px;
  position: absolute;
  margin: 0;
}


.pollutantSelectorWindow .react-datepicker,
.pollutantSelectorWindowLocation .react-datepicker {
  z-index: 15;
  right: 0px;
  top: 0px;
  width: 328px;
  position: absolute;
  margin: 0;
}


.datepicker-withoutTime .react-datepicker {
  z-index: 15;
  right: 0px;
  top: 0px;
  width: 242px;
  position: absolute;
  margin: 0;
}

.pollutantSelectorWindow .react-datepicker-popper,
.pollutantSelectorWindowLocation .react-datepicker-popper {
  margin: 0 !important;
  ;
}

.pollutantSelectorWindow .datepicker-homelocations,
.pollutantSelectorWindowLocation .datepicker-homelocations {
  border-radius: 4px;
  height: 38px;
  width: 190px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px;
  line-height: 24px;
  padding-left: 10px;
}

.pollutantSelectorWindow .datepicker-withoutTime,
.pollutantSelectorWindowLocation .datepicker-withoutTime {
  border-radius: 4px;
  height: 38px;
  width: 190px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px;
  line-height: 24px;
  padding-left: 10px;
}

.pollutantSelectorWindow .btn-controlPanel,
.pollutantSelectorWindowLocation .btn-controlPanel {
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  font-size: 1em;
  color: #fff;
  background: #333;
  padding: 10px 0px;
  border: none;
  cursor: pointer;
  border-radius: .25rem;
  width: 50%;
  text-align: center;
}

.pollutantSelectorWindow .btn-center {
  margin-top: 15px;
}

.pollutantSelectorWindowLocation .btn-center {
  margin-top: 15px;
}

.pollutantSelectorWindow .btn-controlPanel:hover {
  background: #007bff;
  color: #FFF !important;
}

.pollutantSelectorWindow .css-2b097c-container {
  margin-bottom: 10px !important;
}

.css-yk16xz-control {
  border-color: #ddd !important;
}



/* Airquality Dashboard Content */
.airquality-dashboard {
  top: 80px;
  background-color: #f4f4f4;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  width: 275px !important;
  height: 350px !important;
  padding: 30px;
  margin-bottom: 30px;
}

.airquality-dashboard-header {
  font-weight: 600;
  font-size: 30px !important;
  padding: 0 !important;
  margin: 0px 15px !important;
}

.airquality-dashboard-text {
  font-weight: 600;
  font-size: 14px;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.airquality-dashboard-street {
  font-weight: 600;
  font-size: 16px !important;
  padding: 0 !important;
  margin: 0px 15px !important;
}

.airquality-dashboard-value {
  font-weight: 700;
  font-size: 50px;
  padding: 0 !important;
  margin-bottom: 0;
  height: 60px;
  text-align: center;
  margin-top: 7px;
  line-height: 50px;
}

.airquality-status {
  font-weight: 600;
  -webkit-text-stroke: 0.3px #000;
  font-size: 25px;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  padding-bottom: 10px;
}

.airquality-separator {
  border-bottom: 3px solid #ccc;
  padding: 5px 0;
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 1em;
}

.airquality-separator-text {
  position: absolute;
  left: 39%;
  top: 0;
  background: #fff;
  padding: 8px;
  color: rgba(0, 0, 0, .45);
}

.health-effect {
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  fill: "#444"
}

.health-effect-images {
  -webkit-align-items: center;
          align-items: center;
  width: 39px;
  height: 45px;
  margin: 5px 5px;
}

@media (max-width: 480px) {
  .health-effect-images {
    -webkit-align-items: center;
            align-items: center;
    width: 35px;
    height: 35px;
    margin: 5px 5px;
  }

  .col-sm-4,
  .col-md-4 {
    margin: auto !important;
  }
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 15px;
}

.nestead-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 5px 2px;
}

.col-md-12 {
  background-color: #f4f4f4;
  border-radius: 5px;
  border: none !important;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  border: 1px solid black;
  box-shadow: 0px 1px 12px 0px #464646;
  height: 150px !important;
}

.col-sm-4 {
  width: 300px;
  max-width: 320px;
  margin-left: 30px;
}

.col-md-4 {
  min-width: 300px;
  max-width: 300px;
  margin-left: 30px;
}

.col-md-12 {
  min-width: 275px;
  margin-right: 5px;
}

@media (max-width: 1266px) {

  .row {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .col-md-4 {
    margin-left: 30px;
  }
}



.airquality-dashboard-pollutants-name {
  font-weight: 600;
  font-size: 24px !important;
  padding: 0 !important;
  margin: 0px 15px !important;
  margin-top: 10px !important;
}

.measurement-unit {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 24px;
}

.trend-line {
  z-index: 99;
  height: 50px !important;
  width: 40px !important;
}

.settings-airquality {
  position: absolute;
  top: 5px;
  right: 5px;
}


/* Info Menu */

.info {
  text-align: center;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  display: border-box;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.info h1 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.accordion-info-menu {
  width: 90%;
  margin: auto;
  margin-bottom: 30px;
}

.info-table-row {
  height: 18px;
}

.info-table-value {
  width: 450px;
  height: 18px;
  font-weight: 600 !important;
}

.info-table-index {
  width: 140px;
  height: 18px;
  font-weight: 600 !important;
}

.info .table {
  width: 90%;
  margin: 0 5%;
}

.info .table td {
  font-weight: 400;
}

.info .info-section {
  margin: 0;
  margin-bottom: 0.5em;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  text-indent: 15px;
}

.info .info-paragraph {
  text-align: left;
  text-indent: 50px;
  font-size: 16px;
  font-weight: 400;
}

.info .MuiAccordionDetails-root {
  display: block;
}

.info .MuiTypography-body1 {
  font-weight: 600;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.col-md-6 {
  text-align: center;
  margin-left: 30px;
  max-width: 60%;
  -webkit-flex: 1 1 !important;
          flex: 1 1 !important;
}

.col-md-6 .col-md-12 {
  width: 80% !important;
  min-width: 100% !important;
  max-width: 100%;
}

.recommandation-title {
  position: relative;
  vertical-align: middle;
  text-align: center;
  margin: 0 !important;
  padding: 0;
  width: 86% !important;
  margin-left: 35px !important;
  margin-right: 5px !important;

}

.recommandation-window {
  position: relative;
  float: left;
  width: 50px;
  height: 45px;
}

.center {
  height: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 1266px) {
  .recommandation-title {
    margin-left: 20px !important;
    margin-right: 5px !important;

  }

  .col-md-6 {
    max-width: 75%;
  }

  .col-md-6 .col-md-12 {
    width: 80% !important;
    min-width: 100% !important;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .recommandation-title {
    margin-left: 20px !important;
    margin-right: 5px !important;
  }

  .col-md-6 {
    max-width: 100%;
    margin: 0;
  }

}

/*  */

.topnav {
  overflow: hidden;
  background-color: #333;
  width: 87vw;
  height:60px;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.topnav-right {
  float: right;
  display: -webkit-flex;
  display: flex;
}

.topnav .form-control {
  width: 10vw;
  margin-left: 5px;
  margin-top: 5px;
}

.buton {
  border: 2px solid black;
  background-color: black;
  color: green;
  padding: 5px 12px;
  font-size: 24px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 15px;
}

.success {
  background-color: #04AA6D;
  color: white;
}

.react_time_range__time_range_container {
  margin-left: 10%;
}

li {
  display: inline-block;
  margin-left: 30px;
  width: 150px;
}

.topnav .react-datepicker-wrapper{
  top: 13.8%!important;
}

.infoCircle{
  margin-top:15px;
  margin-left:5px;
  cursor: pointer;
  height:25px;
  width:25px;
  color: white;
}


@media (max-width: 1400px) {
  .divButton{
    width:90px;
  }

  li {
    display: inline-block;
    margin-left: 35px;
    width: 80px;
  }

  .form-control{
    width:5vw;
  }

  .topnav a{
    margin-top:8px;
    padding: 14px 5px;
    font-size: 12px!important;
  }

  .infoCircle{
    margin-top:18px;
  }
}

.divButton{
  width:90px;
}
/* Login Component */

/* Button */
.btn-reset-password {
  display: inline-block;
  font-size: 0.98em;
  color: #fff;
  background: #333;
  padding: 12px 15px;
  border: none;
  cursor: pointer;
  border-radius: .25rem;
  width: 67.5%;
}

.btn-reset-password:hover,
.btn-block:hover {
  background: #007bff;
  color: #FFF !important;
}


.errorSendAuthentication {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  height: 45px;
}


/* Forgot password and Reset password Style */

.forgot-password-content p {
  padding-bottom: 10px;
}

.forgot-link {
  -webkit-margin-after: 0;
          margin-block-end: 0;
  font-size: 16px;
}

.forgot-password-content {
  display: inline-block;
  width: 500px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  vertical-align: middle;
  position: relative;
  margin-top: 125px;
  padding: 50px 80px;
}

/* Form */

.form-group .textfield {
  width: 100%;
}

.form-group-login {
  margin-bottom: 25px;
  padding: 0;
}

/* Login */

.login,
.forgot-password {
  text-align: center;
  -webkit-flex: 0.9 1;
          flex: 0.9 1;
  display: border-box;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
}

.login-content {
  display: inline-block;
  width: 530px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  vertical-align: middle;
  position: relative;
  margin-top: 20px;
  padding: 30px;
}

.login-title {
  color: black;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 30px;
}

.login-title h1 {
  padding: 0;
}

.login-separator {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  position: relative;
  display: block;
  margin-bottom: 30px;
  font-size: 1em;
}

.login-separator-text {
  position: absolute;
  left: 46%;
  top: 0;
  background: #fff;
  padding: 8px;
  color: rgba(0, 0, 0, .45);
}

.login input,
.forgot-password-content input {
  width: 100%;
  height: 45px;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: .25rem;
}

.login .textarea {
  height: 200px;
}

.login input:focus {
  outline: none;
  border-color: blue;
}

.social-btn {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 1em;
  background: white;
  height: 45px;
  border: 1px solid #e8e8e8;
  border-radius: .25rem;
  text-align: center;
  color: black;
}

.social-btn:hover{
  background: #eee;
  color: #000 !important;
}

.social-btn img {
  height: 32px;
  float: left;
  -webkit-align-items: center;
          align-items: center;
}

.social-btn.google {
  margin-top: 12px;
}

.social-btn.google img {
  height: 24px;
}

.social-btn.facebook img {
  height: 24px;
}

.social-btn.github img {
  height: 24px;
}

.signup-link {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.Toastify__toast-container {
  margin-top: 29px;
}

/* Mobile Style */

@media (max-width: 768px) {
  .forgot-password-content {
    width: 75%;
    padding: 45px;
  }

  .login-content {
    width: 85%;
    padding: 15px;
  }

  .login-title {
    font-size: 1.4em;
  }

  .social-btn {
    font-size: 0.8em;
  }

  .login input {
    width: 100%;
    padding: 10px;
    border: 1px #ddd solid;
  }
}

@media (max-width: 480px) {

  .forgot-password-content {
    width: 85%;
    padding: 25px;
    margin-top: 100px;
  }

  .btn-reset-password {
    font-size: 0.95em;
    padding: 12px 5px;
    width: 85%;
  }
}

