.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
    margin-top: 52px;
    position: absolute;
    width: 530px;
    
  }
  
  .alert-hidden {
    opacity: 0;
    transition: all 250ms linear;
    display: none;
  }