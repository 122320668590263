/* Login Component */

/* Button */
.btn-reset-password {
  display: inline-block;
  font-size: 0.98em;
  color: #fff;
  background: #333;
  padding: 12px 15px;
  border: none;
  cursor: pointer;
  border-radius: .25rem;
  width: 67.5%;
}

.btn-reset-password:hover,
.btn-block:hover {
  background: #007bff;
  color: #FFF !important;
}


.errorSendAuthentication {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  height: 45px;
}


/* Forgot password and Reset password Style */

.forgot-password-content p {
  padding-bottom: 10px;
}

.forgot-link {
  margin-block-end: 0;
  font-size: 16px;
}

.forgot-password-content {
  display: inline-block;
  width: 500px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  vertical-align: middle;
  position: relative;
  margin-top: 125px;
  padding: 50px 80px;
}

/* Form */

.form-group .textfield {
  width: 100%;
}

.form-group-login {
  margin-bottom: 25px;
  padding: 0;
}

/* Login */

.login,
.forgot-password {
  text-align: center;
  flex: 0.9;
  display: border-box;
  flex-wrap: wrap;
  background: #f9f9f9;
  padding-bottom: 0;
  justify-content: center;
}

.login-content {
  display: inline-block;
  width: 530px;
  background: #fff;
  box-shadow: 0px 1px 12px 0px #464646;
  border-radius: 5px;
  vertical-align: middle;
  position: relative;
  margin-top: 20px;
  padding: 30px;
}

.login-title {
  color: black;
  font-size: 1.6em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 30px;
}

.login-title h1 {
  padding: 0;
}

.login-separator {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  position: relative;
  display: block;
  margin-bottom: 30px;
  font-size: 1em;
}

.login-separator-text {
  position: absolute;
  left: 46%;
  top: 0;
  background: #fff;
  padding: 8px;
  color: rgba(0, 0, 0, .45);
}

.login input,
.forgot-password-content input {
  width: 100%;
  height: 45px;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: .25rem;
}

.login .textarea {
  height: 200px;
}

.login input:focus {
  outline: none;
  border-color: blue;
}

.social-btn {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 1em;
  background: white;
  height: 45px;
  border: 1px solid #e8e8e8;
  border-radius: .25rem;
  text-align: center;
  color: black;
}

.social-btn:hover{
  background: #eee;
  color: #000 !important;
}

.social-btn img {
  height: 32px;
  float: left;
  align-items: center;
}

.social-btn.google {
  margin-top: 12px;
}

.social-btn.google img {
  height: 24px;
}

.social-btn.facebook img {
  height: 24px;
}

.social-btn.github img {
  height: 24px;
}

.signup-link {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.Toastify__toast-container {
  margin-top: 29px;
}

/* Mobile Style */

@media (max-width: 768px) {
  .forgot-password-content {
    width: 75%;
    padding: 45px;
  }

  .login-content {
    width: 85%;
    padding: 15px;
  }

  .login-title {
    font-size: 1.4em;
  }

  .social-btn {
    font-size: 0.8em;
  }

  .login input {
    width: 100%;
    padding: 10px;
    border: 1px #ddd solid;
  }
}

@media (max-width: 480px) {

  .forgot-password-content {
    width: 85%;
    padding: 25px;
    margin-top: 100px;
  }

  .btn-reset-password {
    font-size: 0.95em;
    padding: 12px 5px;
    width: 85%;
  }
}
